<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>签约合同</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelexport">
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名称/手机号</span>
                </template>
                <a-input allowClear v-model.trim="searchData.cellphone" placeholder="学员名称/手机号" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>签约合同号</span>
                </template>
                <a-input allowClear v-model.trim="searchData.contract_no" placeholder="签约合同号" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="签约校区" >
                        <a-select allowClear showSearch @change="changeStudio" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model="searchData.studio_id" placeholder="请选择" :filterOption="filterOption">
                          <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <!-- <a-form-item class="block-line" label="课程收费" >
                        <a-select v-model="searchData.studio_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of courseFees" :key="index" :value="d['fee_id']">{{ d['fee_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item> -->
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="合同类型" >
                        <a-select allowClear v-model="searchData.contract_type" placeholder="请选择">
                          <a-select-option v-for="(d, index) of contractTypes" :key="index" :value="d['contract_type']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="添加时间" >
                        <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
                      </a-form-item>
                      <a-form-item class="block-line" label="课程顾问" > 
                        <a-select allowClear v-model="searchData.course_consultant_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <!-- <a-form-item label="经办人" >
                        <a-select showSearch placeholder="请选择">
                          <a-select-option value="jack">Jack</a-select-option>
                          <a-select-option value="lucy">Lucy</a-select-option>
                          <a-select-option value="Yiminghe">yiminghe</a-select-option>
                        </a-select>
                      </a-form-item> -->
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1500 }">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>

             <template slot="studentName" slot-scope="text, record">
                <LUser :data="record"/>
            </template>
            <template slot="out_trade_no" slot-scope="text,record">
                <a href="javascript:;" @click="showOrderModal(record)">{{ text }}</a>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
      <orderModal v-if="orderVisible && canRead" :item="modalData"/>
    </div>
  </div>
</template>

<script>
const columns = [
 { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '200px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '学员手机号', dataIndex: 'student_cellphone', key: '2' },
  { title: '签约合同号', dataIndex: 'contract_no', key: '1', scopedSlots: { customRender: 'out_trade_no' } },
  { title: '签约校区', dataIndex: 'studio_name', key: '3' },
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' },
  { title: '课程顾问', dataIndex: 'course_consultant', key: 'course_consultant' },
  { title: '课程花费', dataIndex: 'contract_costs', key: '7' },
  { title: '优惠金额', dataIndex: 'contract_discount', key: '8' },
  { title: '签约课时', dataIndex: 'times', key: '10' },
  { title: '赠送课时', dataIndex: 'gift_times', key: '11' },
  // { title: '可请假课时', dataIndex: 'cancel_times', key: '12' },
  { title: '合同类型', dataIndex: 'contract_type', key: '13' ,align:'right'},
  { title: '合同状态', dataIndex: 'contract_status', key: 'contract_status' ,fixed:'right'},
  { title: '经办人', dataIndex: 'created_by', key: '14',fixed:'right' },
  { title: '操作时间', dataIndex: 'created_at', key: '15',fixed:'right' }
]

import C_ITEMS from '@/utils/items'
import orderModal from '@/views/finance/daily/orderModal'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
    orderModal,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      courseFees: [],
      categorys: [],
      contractTypes: [],
      consultants: [],

      searchData: {},

      visible: false,
      refundVisible: false,
      orderVisible: false,
      authType:['orginization','contract'],
    }
  },
  mixins: [ ranges , authority , tableMixins],
  created() {
    this.getStudio()
    this.getCategory()
    this.getContractType()
    this.getConsultant()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async changeStudio(studio) {
      let res = await this.$store.dispatch('searchCourseFeeAction', { data: {studio_id: studio} })
      this.courseFees = res.data
    },
    async getCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.categorys = res.data
    },
    async getContractType() {
      let res = await this.$store.dispatch('searchContractTypeAction', {})
      this.contractTypes = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('contractStudentsAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    showOrderModal(item) {
      this.modalData = item
      this.orderVisible = true
    },
    hideOrderModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.orderVisible = false
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
  }
}
</script>